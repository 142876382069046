import { useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { useMutation } from '@apollo/client';
import { SEND_VAGO_BORDEREAU_EMAIL } from '../../../../queries';
import LoadingButton from '@mui/lab/LoadingButton';
import { useAuth } from '@calefy-inc/authentication';
import { Form, Calendar } from '@calefy-inc/informedMaterial';
import { $TSFixMe } from '@calefy-inc/utilityTypes';
import Stack from '@mui/material/Stack';
import {
  withFormStyles,
  WithFormStyles,
} from '../../../../util/withFormStyles';
import { styled, Typography } from '@mui/material';
import { useAllowByPermission, useUsingVagoSettings } from '../../../../hooks';
import Page404 from '../Page404';
import { InsufficientPermissionsErrorPage } from '../../../common/ErrorHandling';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import Button from '@mui/material/Button';
import { graphql } from '../../../../gql';
import { ErrorP } from '../../../common/Typography';
import { downloadZipFile } from '@calefy-inc/utility';
import { useSnackbar } from 'notistack';
import {
  DownloadVagoBordereau,
  SendVagoBordereauEmail,
} from '../../../../gql/graphql';

interface ManagementPanelBordereauProps
  extends RouteComponentProps,
    WithFormStyles {}
export const ManagementPanelBordereau = withFormStyles()(
  ({ classes }: ManagementPanelBordereauProps) => {
    const [
      sendVagoBordereauEmail,
      { loading: sendLoading, error: sendError, data: sendData },
    ] = useMutation(SEND_VAGO_BORDEREAU_EMAIL, {
      onCompleted: (data) => {
        if (data.sendVagoBordereauEmail) {
          enqueueSnackbar('Sent email!', { variant: 'success' });
        }
      },
    });
    const [
      downloadVagoBordereau,
      { loading: downloadLoading, error: downloadError, data: downloadData },
    ] = useMutation(DOWNLOAD_VAGO_BORDEREAU, {
      onCompleted: async (data) => {
        console.log({ data });
        await downloadZipFile(
          data.downloadVagoBordereau?.data || '',
          data.downloadVagoBordereau?.filename || '',
        );
        enqueueSnackbar('Download complete!', { variant: 'success' });
      },
    });
    const { token } = useAuth();
    const onVago = useUsingVagoSettings();
    const hasBordereauPermissions = useAllowByPermission('send:bordereau');
    const { enqueueSnackbar } = useSnackbar();
    const [sendEmail, setSendEmail] = useState<boolean>(false);

    if (hasBordereauPermissions === false) {
      return <InsufficientPermissionsErrorPage />;
    }
    if (onVago === false) {
      return <Page404 />;
    }
    if (hasBordereauPermissions === undefined || onVago === undefined) {
      return null;
    }
    return (
      <>
        <h1>Bordereau</h1>

        <Typography>
          Please select the start and end date for the bordereau documents. In
          addition, please select whether or not a new copy of the bordereau
          email should be sent.
        </Typography>
        <Form
          initialValues={getPreviousMonthDates()}
          onSubmit={async (values: $TSFixMe) => {
            const { startDate, endDate } = values;
            downloadVagoBordereau({
              variables: {
                token,
                startDate: convertFormTimeToCorrect(startDate),
                endDate: convertFormTimeToCorrect(endDate),
              },
            });
            if (sendEmail) {
              sendVagoBordereauEmail({
                variables: {
                  token,
                  startDate: convertFormTimeToCorrect(startDate),
                  endDate: convertFormTimeToCorrect(endDate),
                },
              });
            }
          }}
        >
          <Stack spacing={4} sx={{ marginTop: 2 }}>
            <Calendar
              field='startDate'
              label='Start Date'
              required={true}
              classes={classes.calendar}
            />
            <Calendar
              field='endDate'
              label='End Date'
              required={true}
              classes={classes.calendar}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={sendEmail}
                  onChange={(e) => {
                    setSendEmail(e.target.checked);
                  }}
                />
              }
              label='Send Email?'
            />
            <SubmitButtonContainer>
              <LoadingButton
                type='submit'
                loading={sendLoading || downloadLoading}
                variant='contained'
                startIcon={<DownloadRoundedIcon />}
              >
                Download Bordereau{sendEmail ? ' and Send Email' : ''}
              </LoadingButton>
            </SubmitButtonContainer>
          </Stack>
        </Form>
        <MutationResultsContainer>
          <ul>
            {downloadError ? (
              <li>
                <DisplayDownloadError error={downloadError} />
              </li>
            ) : null}
            {sendError ? (
              <li>
                <DisplaySendError error={sendError} />
              </li>
            ) : null}
          </ul>

          <ul>
            {sendData ? (
              <li>
                <DisplaySendEmailSuccess
                  data={sendData?.sendVagoBordereauEmail}
                />
              </li>
            ) : null}
            {downloadData ? (
              <li>
                <DisplayDownloadSuccess
                  data={downloadData?.downloadVagoBordereau}
                />
              </li>
            ) : null}
          </ul>
        </MutationResultsContainer>
      </>
    );
  },
);

interface DisplaySendEmailSuccessProps {
  data: SendVagoBordereauEmail | null | undefined;
}
const DisplaySendEmailSuccess = ({ data }: DisplaySendEmailSuccessProps) => {
  if (data) {
    return (
      <Typography>
        Sent bordeau document email for {data.startDate} - {data.endDate} to{' '}
        {data.emails
          ? // @ts-expect-error
            new Intl.ListFormat('en', {
              style: 'long',
              type: 'conjunction',
            }).format(data.emails)
          : 'unknown recipients'}
      </Typography>
    );
  }
  return null;
};
interface DisplayDownloadSuccessProps {
  data: DownloadVagoBordereau | null | undefined;
}
const DisplayDownloadSuccess = ({ data }: DisplayDownloadSuccessProps) => {
  if (data) {
    return (
      <>
        <Typography>Successfully downloaded {data.filename}!</Typography>
        {data.data ? (
          <Button
            onClick={async () => {
              await downloadZipFile(
                // @ts-expect-error
                data.data,
                data.filename || 'bordereau.zip',
              );
            }}
          >
            Download Again
          </Button>
        ) : null}
      </>
    );
  }
  return null;
};

const MutationResultsContainer = styled('div')(({ theme }) => {
  return {
    margin: theme.spacing(2, 0, 2, 3),
  };
});
const SubmitButtonContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
});

interface WithErrorProps {
  error?: Error;
}
const DisplayDownloadError = ({ error }: WithErrorProps) => {
  if (!error) {
    return null;
  }
  return (
    <ErrorP>Error downloading bordereau documents: {error.message}</ErrorP>
  );
};
const DisplaySendError = ({ error }: WithErrorProps) => {
  if (!error) {
    return null;
  }
  return <ErrorP>Error sending bordereau email: {error.message}</ErrorP>;
};

const convertFormTimeToCorrect = (rawDate: string | Date) => {
  if (typeof rawDate === 'string') {
    const [month, day, year] = rawDate.split('/');
    return [year, month, day].join('-');
  }
  const year = rawDate.getFullYear();
  const month = String(rawDate.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const day = String(rawDate.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

const getPreviousMonthDates = () => {
  const now = new Date();

  const year = now.getFullYear();
  const prevMonth = now.getMonth() - 1;
  // Get the first day of the previous month
  const startDate = new Date(year, prevMonth, 1);

  // Get the last day of the previous month
  const endDate = new Date(year, prevMonth + 1, 0);

  return { startDate, endDate };
};

const DOWNLOAD_VAGO_BORDEREAU = graphql(`
  mutation downloadVagoBordereau(
    $token: String!
    $startDate: Date!
    $endDate: Date!
  ) {
    downloadVagoBordereau(
      token: $token
      startDate: $startDate
      endDate: $endDate
    ) {
      startDate
      endDate
      data
      filename
    }
  }
`);
