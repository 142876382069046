import { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import { Link as RouterLink, Match, useMatch } from '@reach/router';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
// material
import { alpha, useTheme, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemButton, {
  ListItemButtonProps,
} from '@mui/material/ListItemButton';
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import { useUsingVagoSettings } from '../../../hooks';
import {
  bordereauSidebarItem,
  SidebarConfig,
} from '../layouts/dashboard/SidebarConfig';

// ----------------------------------------------------------------------

const ListSubheaderStyle = styled((props) => (
  <ListSubheader disableSticky disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.overline,
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
  paddingLeft: theme.spacing(5),
  color: theme.palette.text.primary,
}));

const ListItemStyle = styled((props: ListItemButtonProps) => (
  <ListItemButton disableGutters {...props} />
))<ListItemButtonProps & { component?: React.ElementType; to?: string }>(
  ({ theme }) => ({
    ...theme.typography.body2,
    height: 48,
    position: 'relative',
    textTransform: 'capitalize',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(2.5),
    color: theme.palette.text.secondary,
    '&:before': {
      top: 0,
      right: 0,
      width: 3,
      bottom: 0,
      content: "''",
      display: 'none',
      position: 'absolute',
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      backgroundColor: theme.palette.primary.main,
    },
  }),
);

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

// ----------------------------------------------------------------------

interface NavItemProps {
  item: $TSFixMe; // object
  active?: $TSFixMe; //function
  isShow: boolean;
}
function NavItem({ item, isShow }: NavItemProps) {
  const theme = useTheme();
  const isActiveRoot = useMatch(item.path + '/*');
  const { title, path, icon, info, children } = item;
  const [open, setOpen] = useState<boolean>(!!isActiveRoot);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity,
    ),
    '&:before': { display: 'block' },
  };

  const activeSubStyle = {
    color: 'text.primary',
    fontWeight: 'fontWeightMedium',
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>

          {isShow && (
            <>
              <ListItemText disableTypography primary={title} />
              {info && info}
              <Box
                component={Icon}
                icon={open ? arrowIosDownwardFill : arrowIosForwardFill}
                sx={{ width: 16, height: 16, ml: 1 }}
              />
            </>
          )}
        </ListItemStyle>

        {isShow && (
          <Collapse in={open} timeout='auto' unmountOnExit>
            <List component='div' disablePadding>
              {children.map((item: $TSFixMe) => {
                const { title, path } = item;

                return (
                  <Match path={path + '/*'} key={title}>
                    {(props) => (
                      <ListItemStyle
                        key={title}
                        component={RouterLink}
                        to={path}
                        sx={{
                          ...(props.match && activeSubStyle),
                        }}
                      >
                        <ListItemIconStyle>
                          <Box
                            component='span'
                            sx={{
                              width: 4,
                              height: 4,
                              display: 'flex',
                              borderRadius: '50%',
                              alignItems: 'center',
                              justifyContent: 'center',
                              bgcolor: 'text.disabled',
                              transition: (theme) =>
                                theme.transitions.create('transform'),
                              ...(props.match && {
                                transform: 'scale(2)',
                                bgcolor: 'primary.main',
                              }),
                            }}
                          />
                        </ListItemIconStyle>
                        <ListItemText disableTypography primary={title} />
                      </ListItemStyle>
                    )}
                  </Match>
                );
              })}
            </List>
          </Collapse>
        )}
      </>
    );
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      {isShow && (
        <>
          <ListItemText disableTypography primary={title} />
          {info && info}
        </>
      )}
    </ListItemStyle>
  );
}

interface NavSectionProps {
  navConfig: Array<$TSFixMe>; // array of some sort?
  isShow: boolean;
  [key: string]: $TSFixMe;
}
export default function NavSection({
  navConfig,
  isShow = true,
  ...other
}: NavSectionProps) {
  const [actualNavConfig, setActualNavConfig] =
    useState<SidebarConfig>(navConfig);
  const onVago = useUsingVagoSettings();

  useEffect(() => {
    if (onVago) {
      setActualNavConfig(
        navConfig.map((navItem) => {
          if (navItem.subheader === 'management') {
            return {
              ...navItem,
              items: [
                // @ts-expect-error
                ...navItem.items.filter((item) => item.title !== 'database'),
                bordereauSidebarItem,
                // @ts-expect-error
                ...navItem.items.filter((item) => item.title === 'database'),
              ],
            };
          }
          return navItem;
        }),
      );
    }
  }, [onVago, navConfig]);

  return (
    <Box {...other}>
      {actualNavConfig.map((list) => {
        const { subheader, items } = list;
        return (
          <List key={subheader} disablePadding>
            {isShow && <ListSubheaderStyle>{subheader}</ListSubheaderStyle>}
            {items.map((item: $TSFixMe) => (
              <NavItem key={item.title} item={item} isShow={isShow} />
            ))}
          </List>
        );
      })}
    </Box>
  );
}
