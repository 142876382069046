import theme from './VagoTheme';
import logo from './assets/MainLogo.png';
import squareLogo from './assets/VagoSquareLogo.png';
import favicon from './assets/favicon.ico';
import { WebsiteSettings } from '../../hooks/useSettings/WebsiteSettings';
import { generateUseUseSettings } from '../../hooks/generateUseUsingSettings';
import { VAGO_BUSINESS_INTERNAL_NAME } from './constants';

// Calefy Config
const settings = new WebsiteSettings({
  slug: 'vago',
  host: 'secure.vagoassure.ca',
  companyName: 'VaGo Assure',
  hours: '9:00am - 5:00pm',
  // @ts-expect-error
  phone: null,
  // @ts-expect-error
  email: null,
  // @ts-expect-error
  chatLink: null,
  companyTheme: theme,
  logo: logo,
  title: 'VaGo Assure',
  favicon: favicon,
  sendEmailOnContactInformationCompleted: false,
  squareLogo,
  contactInformationFirst: false,
  businessFilter: (business) =>
    business.internalName === VAGO_BUSINESS_INTERNAL_NAME,
  allowApplicationUpload: true,
});

export default settings;

export const useUsingVagoSettings = generateUseUseSettings(settings);
