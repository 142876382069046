import React, { ComponentType } from 'react';
import { Theme, createTheme } from '@mui/material/styles';
import { v4 as uuidv4 } from 'uuid';
import type { $TSFixMe } from '@calefy-inc/utilityTypes';

export interface WebsiteSettingsInterface {
  readonly slug: string;
  readonly host: string;
  readonly personalInsurance: boolean;
  readonly companyInsurType: string;
  readonly companyName: string;
  readonly hours: string;
  readonly phone: string;
  readonly email: string;
  readonly chatLink: string;
  readonly privacyLink: string;
  readonly companyTheme: Theme;
  readonly logo: string;
  readonly businessFilter: (business: $TSFixMe) => boolean;
  readonly tileIcons: (internalName: string) => React.ReactNode;
  readonly title: string;
  readonly favicon: string;
  readonly mobileLogo: string;
  readonly squareLogo: string;
  readonly thankYouText: string;
  readonly sendEmailOnContactInformationCompleted: boolean;
  readonly contactInformationFirst: boolean;
  readonly headerCss?: $TSFixMe; // TODO this should be whatever type a CSS object is
  readonly Footer?: ComponentType;
  readonly allowApplicationUpload: boolean;
}

/**
 *  @param slug - a unique identifier for the particular settings
 *  @param host - the website host (used for matching)
 *  @param personalInsurance - does this brokerage provide personal insurance
 *  @param companyInsurType - what types of insurance this company offers
 *  @param companyName - The displayed name of the company or organization
 *  @param hours - business hours in a human-readable string
 *  @param phone - a phone number to reach them
 *  @param email: a contact email
 *  @param chatLink: a link to an online chat
 *  @param privacyLink: a link to the privacy policy
 *  @param companyTheme: the theme for this particular whitelabel
 *  @param logo: a logo to be displayed
 *  @param businessFilter: a function which returns true for businesses that this whitelabel can handle and false otherwise
 *  @param tileIcons: a function that returns custom icons for use in the TileSelector
 *  @param title: The website title for this whitelabel
 *  @param favicon: The favicon
 *  @param mobileLogo: a logo for mobile
 *  @param thankYouText: The text that notifies the user that they will be contacted within 24-48 hours
 *  @param sendEmailOnContactInformationCompleted: whether or not to send an email and display an indicator page when they have finished completing the contact information question
 *  @param contactInformationFirst - Should a form with the contact information questions be presented before the policy selection page?
 *  @param headerCss - CSS to apply to the header (quotewizard) - actually stands in the sx prop, so you can use theme-specific stuff here
 *  @param Footer - A component to use for the footer in the QuoteWizard
 *  @param allowApplicationUpload - should we display the upload application button (generates an application from a pdf or whatnot)
 */
export class WebsiteSettings implements WebsiteSettingsInterface {
  slug: string;
  host: string;
  personalInsurance: boolean;
  companyInsurType: string;
  companyName: string;
  hours: string;
  phone: string;
  email: string;
  chatLink: string;
  privacyLink: string;
  companyTheme: Theme;
  logo: string;
  businessFilter: (business: $TSFixMe) => boolean;
  tileIcons: (internalName: string) => React.ReactNode;
  title: string;
  favicon: string;
  mobileLogo: string;
  squareLogo: string;
  sendEmailOnContactInformationCompleted: boolean;
  contactInformationFirst: boolean;
  thankYouText: string;
  headerCss: $TSFixMe;
  Footer?: ComponentType;
  allowApplicationUpload: boolean;

  constructor({
    slug = uuidv4(),
    host = '',
    personalInsurance = false,
    companyInsurType = 'both',
    companyName = '',
    hours = '',
    phone = '',
    email = '',
    chatLink = '',
    privacyLink = '',
    companyTheme = createTheme(),
    logo = '',
    businessFilter = (_business: $TSFixMe) => true,
    tileIcons = (_internalName: $TSFixMe) => undefined,
    title = '',
    favicon = '',
    mobileLogo = '',
    squareLogo = '',
    sendEmailOnContactInformationCompleted = false,
    // sendEmailOnContactInformationCompleted = true, // for testing only
    contactInformationFirst = false,
    headerCss = undefined,
    thankYouText = '',
    Footer,
    allowApplicationUpload = false,
  }: Partial<WebsiteSettingsInterface>) {
    this.slug = slug;
    this.host = host;
    this.personalInsurance = personalInsurance;
    this.companyInsurType = companyInsurType;
    this.companyName = companyName;
    this.hours = hours;
    this.phone = phone;
    this.email = email;
    this.chatLink = chatLink;
    this.privacyLink = privacyLink;
    this.companyTheme = companyTheme;
    this.logo = logo;
    this.businessFilter = businessFilter;
    this.tileIcons = tileIcons;
    this.title = title;
    this.favicon = favicon;
    this.mobileLogo = mobileLogo;
    this.squareLogo = squareLogo;
    this.sendEmailOnContactInformationCompleted =
      sendEmailOnContactInformationCompleted;
    this.contactInformationFirst = contactInformationFirst;
    this.thankYouText = thankYouText;
    this.headerCss = headerCss;
    this.Footer = Footer;
    this.allowApplicationUpload = allowApplicationUpload;
  }
}
