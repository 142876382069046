// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/insurtech/manager';

const generateRootDashboardUrl = (sublink: string) => {
  return `${ROOTS_DASHBOARD}${sublink[0] === '/' ? '' : '/'}${sublink}`;
};

// ----------------------------------------------------------------------

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  page404: '/404',
  page500: '/500',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    dashboard: path(ROOTS_DASHBOARD, '/dashboard'),
  },
  quotes: {
    root: path(ROOTS_DASHBOARD, '/quotes'),
    create: path(ROOTS_DASHBOARD, '/quotes/create'),
    details: path(ROOTS_DASHBOARD, '/quotes/details/:uniqueID'),
  },
  forms: {
    root: path(ROOTS_DASHBOARD, '/forms'),
    edit: path(ROOTS_DASHBOARD, '/forms/edit/:businessId/:policyId/'),
  },
  formModifiers: {
    root: generateRootDashboardUrl('formmodifiers'),
    edit: generateRootDashboardUrl('formmodifiers/edit/:businessId/:policyId'),
  },
  rates: {
    root: generateRootDashboardUrl('rates'),
  },
  database: {
    root: path(ROOTS_DASHBOARD, '/database'),
    business: path(ROOTS_DASHBOARD, '/database/business'),
    policy: path(ROOTS_DASHBOARD, '/database/policy'),
    questions: path(ROOTS_DASHBOARD, '/database/questions'),
  },
  bordereau: path(ROOTS_DASHBOARD, '/bordereau'),
  pinkSlip: path(ROOTS_DASHBOARD, '/pink-slip'),
  accountingWidget: path(ROOTS_DASHBOARD, '/accounting-widget'),
  prospects: path(ROOTS_DASHBOARD, '/prospects'),
};
