/*
 * NB You can actually upload different file types here as well
 */
import { useState, ChangeEvent, useEffect } from 'react';
import Button from '@mui/material/Button';
import { BusinessType } from '../../Typescript';
import { VisuallyHiddenInput } from '../common/VisuallyHiddenInput';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material';
import { useMutation, useLazyQuery } from '@apollo/client';
import { CREATE_QUOTE_FROM_PDF, GET_TASK_POOL } from '../../queries';
import { useNavigate } from '@reach/router';
import {
  CoreTaskStatusChoices,
  GetTaskPoolQuery,
  CreateQuoteFromPdfMutation,
} from '../../gql/graphql';
import Bugsnag from '@bugsnag/browser';
import { errorify } from '@calefy-inc/utility';
import { LinearProgress } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import { ErrorP } from '../common/Typography';
import { SupportLink } from '../common/SupportLink';
import { useSettings } from '../../hooks';
import ErrorIcon from '@mui/icons-material/Error';
import { isNotNullish } from '../../util';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useAuth } from '@calefy-inc/authentication';
import { useSelector } from 'react-redux';
import { StoreState } from '../../store';
import { QuoteWizardAnswerInstance } from '../QuoteWizard/classes';

type BackendTask = NonNullable<GetTaskPoolQuery['getTaskPool']>['tasks'];

const POLLING_INTERVAL_MS = 500;

interface UploadedFile {
  data: string; // base64 encoded contents
  filename: string;
}

const onFirstPageOfGeneralInformation = (state: StoreState) => {
  const { currentStep, componentMapping } = state.quoteWizard.stepWizard;
  const generalInformationStep = componentMapping['General Information'];
  return currentStep === generalInformationStep;
};
const getLoadedFormIds = (state: StoreState) => {
  const { quoteWizard } = state;
  const businessId =
    'id' in quoteWizard.businessForm ? quoteWizard.businessForm.id : null;
  const policyFormIds = quoteWizard.policyForms
    .filter((policyForm) =>
      quoteWizard.selectedPolicies.some(
        (policy) => policyForm.policy && policy.id === policyForm.policy.id,
      ),
    )
    .map((form) => form.id);
  return [businessId, ...policyFormIds].filter(isNotNullish);
};

const hasTerminalAnswers = (answer: QuoteWizardAnswerInstance): boolean => {
  if (answer.questionInstance.subQuestions.length === 0) {
    return (
      answer.value && answer.value !== answer.questionInstance.defaultValue
    );
  }
  return answer.subAnswers.some(hasTerminalAnswers);
};
const hasNonDefaultAnswers = (state: StoreState): boolean => {
  const answeredForms = Object.values(state.quoteWizard.formAnswers);
  if (answeredForms.length == 0) {
    return false;
  }
  return answeredForms.some((answeredForm) => {
    return (
      answeredForm.answers.length > 0 &&
      answeredForm.answers.some(hasTerminalAnswers)
    );
  });
};

interface CreateApplicationFromPdfButtonProps {
  businessLine: BusinessType;
}
export const CreateApplicationFromPdfButton = ({
  businessLine,
}: CreateApplicationFromPdfButtonProps) => {
  const { token } = useAuth();
  const onFirstPage = useSelector(onFirstPageOfGeneralInformation);
  const { allowApplicationUpload } = useSettings();
  const hasAnsweredQuestions = useSelector(hasNonDefaultAnswers);
  const loadedFormIds = useSelector(getLoadedFormIds);
  const [files, setFiles] = useState<Array<UploadedFile>>([]);
  const [uploadFilesDialogOpen, setUploadFilesDialogOpen] =
    useState<boolean>(false);
  const [processApplicationDialogOpen, setProcessApplicationDialogOpen] =
    useState<boolean>(false);
  const [createQuoteFromPdf, { error, data }] = useMutation(
    CREATE_QUOTE_FROM_PDF,
  );

  if (
    !token ||
    !onFirstPage ||
    !allowApplicationUpload ||
    hasAnsweredQuestions
  ) {
    return null;
  }
  const addFile = (f: UploadedFile) => setFiles((oldFiles) => [...oldFiles, f]);
  const removeFile = (f: UploadedFile) =>
    setFiles((oldFiles) => oldFiles.filter((oldFile) => oldFile !== f));
  const onSubmitFiles = () => {
    setUploadFilesDialogOpen(false);
    setProcessApplicationDialogOpen(true);
    createQuoteFromPdf({
      variables: {
        token,
        businessLineId: businessLine.id,
        formIds: loadedFormIds,
        files,
      },
    });
  };
  return (
    <>
      <Button
        variant='outlined'
        startIcon={<UploadFileIcon />}
        onClick={() => setUploadFilesDialogOpen(true)}
      >
        Upload Application
      </Button>
      <UploadFilesDialog
        open={uploadFilesDialogOpen}
        setOpen={(open: boolean) => setUploadFilesDialogOpen(open)}
        files={files}
        addFile={addFile}
        removeFile={removeFile}
        onSubmit={onSubmitFiles}
      />
      <ProcessApplicationDialog
        error={error}
        data={data}
        open={processApplicationDialogOpen}
        onSuccess={() => setProcessApplicationDialogOpen(false)}
      />
    </>
  );
};

interface UploadFilesDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  files: Array<UploadedFile>;
  addFile: (f: UploadedFile) => void;
  removeFile: (f: UploadedFile) => void;
  onSubmit: () => void;
}
const UploadFilesDialog = ({
  open,
  setOpen,
  addFile,
  removeFile,
  files,
  onSubmit,
}: UploadFilesDialogProps) => {
  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (event.target.files && event.target.files.length > 0) {
      const files = Array.from(event.target.files);
      files.forEach((file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          addFile({
            data: reader.result as string,
            filename: file.name,
          });
        };
        reader.onerror = (error) => {
          console.error('Error: ', error);
        };
      });
    }
  };
  return (
    <Dialog open={open}>
      <DialogTitle>Upload Files</DialogTitle>
      <DialogContent>
        <UploadedFilesContainer>
          {files.length === 0 ? (
            <Typography>
              No files selected! Use the button below to begin adding files.
            </Typography>
          ) : (
            files.map((file) => (
              <DisplayUploadedFile file={file} removeFile={removeFile} />
            ))
          )}
        </UploadedFilesContainer>
        <UploadFilesButtonContainer>
          <Button
            component='label'
            variant='outlined'
            startIcon={<UploadFileIcon />}
          >
            Upload Documents
            <VisuallyHiddenInput
              type='file'
              accept='.pdf,.xlsx,.xls,.doc,.docx'
              onChange={handleFileUpload}
              multiple
            />
          </Button>
        </UploadFilesButtonContainer>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button
            variant='contained'
            disabled={files.length === 0}
            onClick={onSubmit}
          >
            Create Application
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

const UploadFilesButtonContainer = styled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  };
});

const UploadedFilesContainer = styled('div')(({ theme }) => {
  return {
    padding: theme.spacing(2, 0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  };
});

interface DisplayUploadedFileProps {
  file: UploadedFile;
  removeFile: (f: UploadedFile) => void;
}
const DisplayUploadedFile = ({
  file,
  removeFile,
}: DisplayUploadedFileProps) => {
  return (
    <DisplayUploadedFileContainer>
      <FilenameAndIconContainer>
        <DisplayUploadedFileIcon filename={file.filename} />
        <Typography>{file.filename}</Typography>
      </FilenameAndIconContainer>
      <IconButton onClick={() => removeFile(file)}>
        <ColouredCloseIcon />
      </IconButton>
    </DisplayUploadedFileContainer>
  );
};

const FilenameAndIconContainer = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(1),
  };
});

interface DisplayUploadedFileIconProps {
  filename: UploadedFile['filename'];
}
const FILETYPE_ICON_SIZE = '24px';
const DisplayUploadedFileIcon = ({
  filename,
}: DisplayUploadedFileIconProps) => {
  if (filename.match(/\.docx?$/i)) {
    return (
      <img
        src='/icons/docx_icon.svg'
        height={FILETYPE_ICON_SIZE}
        width={FILETYPE_ICON_SIZE}
      />
    );
  }
  if (filename.match(/\.xlsx?$/i)) {
    return (
      <img
        src='/icons/xlsx_icon.svg'
        height={FILETYPE_ICON_SIZE}
        width={FILETYPE_ICON_SIZE}
      />
    );
  }
  if (filename.match(/\.pdf$/i)) {
    return (
      <img
        src='/icons/pdf_icon.svg'
        height={FILETYPE_ICON_SIZE}
        width={FILETYPE_ICON_SIZE}
      />
    );
  }
  return null;
};

const ColouredCloseIcon = styled(CloseIcon)(({ theme }) => {
  return { color: theme.palette.error.main };
});

const DisplayUploadedFileContainer = styled('div')(({ theme }) => {
  return {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: theme.spacing(1),
    border: `solid ${theme.spacing(0.25)} ${theme.palette.secondary.main}`,
    margin: theme.spacing(0.5),
    padding: theme.spacing(1),
  };
});

interface ProcessApplicationDialogProps {
  error: Error | undefined;
  data: CreateQuoteFromPdfMutation | undefined | null;
  open: boolean;
  onSuccess: () => void;
}
const ProcessApplicationDialog = ({
  error,
  data,
  open,
  onSuccess,
}: ProcessApplicationDialogProps) => {
  const [destinationUuid, setDestinationUuid] = useState<string>();
  const [tasks, setTasks] = useState<Array<BackendTask>>([]);
  const [aboutToNavigate, setAboutToNavigate] = useState<boolean>(false);
  const navigate = useNavigate();
  const [
    getTaskPool,
    { error: taskPoolError, data: taskPoolData, stopPolling },
  ] = useLazyQuery(GET_TASK_POOL);

  // once the data has been loaded, start polling for the task pool
  useEffect(() => {
    if (data?.createQuoteFromPdf?.taskPoolId) {
      getTaskPool({
        variables: { taskPoolId: data.createQuoteFromPdf.taskPoolId },
        // @ts-expect-error
        pollInterval: POLLING_INTERVAL_MS,
      });
    }
  }, [data]);

  // set the tasks and the destination uuid if that becomes part of the task pool
  useEffect(() => {
    if (taskPoolData?.getTaskPool) {
      try {
        const additionalInformationJson =
          taskPoolData?.getTaskPool?.additionalInformation;
        if (additionalInformationJson) {
          const parsed = JSON.parse(additionalInformationJson);
          const uuid = parsed.quoteUuid;
          if (uuid) {
            setDestinationUuid(uuid);
          }
        }
      } catch (e) {
        console.error(e);
        Bugsnag.notify(errorify(e));
      }
      setTasks(
        // @ts-expect-error
        [...taskPoolData.getTaskPool.tasks]
          .filter(isNotNullish)
          // @ts-expect-error
          .sort((a, b) => a.order - b.order),
      );
    }
  }, [taskPoolData]);

  // stop polling if there's an error or if all of the tasks are complete
  useEffect(() => {
    if (!stopPolling) {
      return;
    }
    if (taskPoolError) {
      stopPolling();
    }
    // NB we don't do this because the last task is completed *and then* we attach the uuid -> possible to be stuck in a state where they are all complete but no uuid to navigate to
    // if (
    //   taskPoolData?.getTaskPool?.tasks &&
    //   taskPoolData.getTaskPool.tasks.length > 0 &&
    //   taskPoolData.getTaskPool.tasks.every(
    //     (task) => task.status === CoreTaskStatusChoices.Completed,
    //   )
    // ) {
    //   stopPolling();
    // }
    if (aboutToNavigate) {
      stopPolling();
    }
    if (taskPoolData?.getTaskPool?.error) {
      stopPolling();
    }
  }, [taskPoolError, taskPoolData, aboutToNavigate]);

  useEffect(() => {
    if (destinationUuid) {
      setAboutToNavigate(true);
    }
  }, [destinationUuid]);

  // set the timer to navigate
  useEffect(() => {
    if (aboutToNavigate) {
      setTimeout(() => {
        onSuccess();
        navigate(`/insurtech/quote/resume/${destinationUuid}`);
      }, 2000);
    }
  }, [aboutToNavigate]);

  //console.log({ tasks });

  return (
    <Dialog open={open}>
      <DialogTitle>Uploading Quote / Application Document</DialogTitle>
      <DialogContent sx={{ marginBottom: 0 }}>
        {tasks.length > 0 &&
        tasks.some(
          // @ts-expect-error
          (task) => task.status === CoreTaskStatusChoices.Pending,
        ) &&
        !error &&
        !taskPoolError ? (
          <Typography sx={{ my: 2 }}>Creating Application...</Typography>
        ) : null}
        <DisplayTaskProgress tasks={tasks} />
        <ErrorDisplay
          createError={error}
          taskPoolError={taskPoolError}
          // @ts-expect-error
          taskPoolErrorString={taskPoolData?.getTaskPool?.error}
        />
        {aboutToNavigate ? (
          <Typography
            variant='h4'
            component='p'
            sx={{
              mt: 4,
              textAlign: 'center',
            }}
          >
            About to load quote...
          </Typography>
        ) : null}
      </DialogContent>
      <DialogActions sx={{ marginTop: 0 }}></DialogActions>
    </Dialog>
  );
};

interface DisplayTaskProgressContainer {
  tasks: Array<BackendTask>;
}
const DisplayTaskProgress = ({ tasks }: DisplayTaskProgressContainer) => {
  return (
    <DisplayTaskProgressContainer>
      {tasks.map((task, taskIndex) => {
        let value = 0;
        // @ts-expect-error
        if (task.status === CoreTaskStatusChoices.Completed) {
          value = 100;
        }
        return (
          <>
            {taskIndex > 0 ? (
              <DividerLinearProgress
                key={
                  // @ts-expect-error
                  task.id
                }
                value={value}
                variant='determinate'
              />
            ) : null}
            <TaskDisplay
              task={task}
              key={
                // @ts-expect-error
                task.id
              }
            />
          </>
        );
      })}
    </DisplayTaskProgressContainer>
  );

  {
    /* return <pre>{JSON.stringify(tasks, null, 4)}</pre>; */
  }
};

const DividerLinearProgress = styled(LinearProgress)({
  width: '5rem',
  // width: `calc(100% - ${2 * 1.5}rem)`,
  transform: `translate(0, -1.5rem)`, // NB this is fragile - it depends on at least on piece of text wrapping to the next line
  top: 0,
  height: '.15rem',
});

const DisplayTaskProgressContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  alignSelf: 'flex-start',
});

const AnimatedLoadingCircle = styled(ChangeCircleIcon)(
  `
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  animation: rotate 2s linear infinite;
`,
);

interface TaskDisplayProps {
  task: BackendTask;
}
const TaskDisplay = ({ task }: TaskDisplayProps) => {
  return (
    <TaskDisplayContainer>
      {
        // @ts-expect-error
        task.status === CoreTaskStatusChoices.Completed ? (
          <CheckCircleIcon color={'primary'} />
        ) : // @ts-expect-error
        task.status === CoreTaskStatusChoices.Pending ? (
          <AnimatedLoadingCircle />
        ) : // @ts-expect-error
        task.status === CoreTaskStatusChoices.Failed ? (
          <ErrorIcon sx={{ color: 'error.main' }} />
        ) : (
          <span></span>
        )
      }
      <Typography>
        {
          // @ts-expect-error
          task.description
        }
        {
          // @ts-expect-error
          task.numSubtasks > 0
            ? // @ts-expect-error
              ` (${task.numCompletedSubtasks} / ${task.numSubtasks})`
            : null
        }
      </Typography>
    </TaskDisplayContainer>
  );
};

const TaskDisplayContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  alignSelf: 'flex-start',
  textAlign: 'center',
});

interface ErrorDisplayProps {
  createError?: Error;
  taskPoolError?: Error;
  taskPoolErrorString?: string;
}
const ErrorDisplay = ({
  createError,
  taskPoolError,
  taskPoolErrorString,
}: ErrorDisplayProps) => {
  const [message, setMessage] = useState<string>();

  useEffect(() => {
    if (createError) {
      setMessage(createError.message);
    } else if (taskPoolError) {
      setMessage(taskPoolError.message);
    } else if (taskPoolErrorString) {
      setMessage(taskPoolErrorString);
    } else {
      setMessage('');
    }
  }, [createError, taskPoolError, taskPoolErrorString]);

  if (!message) {
    return null;
  }
  return (
    <>
      <ErrorP>An unexpected error occurred: {message}.</ErrorP>
      <ErrorP>
        Please try again in a few minutes. If the error persists or you have any
        questions, please contact support at{' '}
        <SupportLink subject={`Error loading quote from pdf: ${message}`} />.
      </ErrorP>
    </>
  );
};
