import { graphql } from '../../gql';

export const CREATE_QUOTE_FROM_PDF = graphql(`
  mutation createQuoteFromPdf(
    $token: String!
    $businessLineId: ID!
    $formIds: [ID!]!
    $files: [FileInput!]!
  ) {
    createQuoteFromPdf(
      token: $token
      businessLineId: $businessLineId
      formIds: $formIds
      files: $files
    ) {
      taskPoolId
    }
  }
`);
