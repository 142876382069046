// routes
/* import Router from './routes'; */
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import { Router } from '@reach/router';
import { ProgressBarStyle } from './components/LoadingScreen';
import { Redirect } from '@reach/router';

// contexts
import { SettingsProvider } from './contexts/SettingsContext';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';
import { IsManagerProvider } from '../../hooks';
// Authenthication
import { Authenticated } from '../Authentication/Authenticated';
import Dashboard from './pages/dashboard/GeneralDashboard';
import DashboardLayout from './layouts/dashboard';

// components
import { QuoteList } from './pages/dashboard/QuoteList';
import QuoteCreate from './pages/dashboard/QuoteCreate';
import QuoteDetails from './pages/dashboard/QuoteDetails';
import InsurerQuoteDetails from './pages/dashboard/InsurerQuoteDetails/InsurerQuoteDetails';
import FormList from './pages/dashboard/FormList';
import FormEdit from './pages/dashboard/FormEdit';
import FormCreate from './pages/dashboard/FormCreate';
import BusinessList from './pages/dashboard/BusinessList';
import BusinessCreate from './pages/dashboard/BusinessCreate';
import BusinessEdit from './pages/dashboard/BusinessCreate';
import PolicyList from './pages/dashboard/PolicyList';
import PolicyCreate from './pages/dashboard/PolicyCreate';
import PolicyEdit from './pages/dashboard/PolicyCreate';
import NotFound from './pages/Page404';
import {
  FormModifiersList,
  FormModifierCreate,
  FormModifierEdit,
} from './pages/dashboard/FormModifiers';
import { RatesEditor } from './pages/dashboard/Rates';
import { QuestionsTablePage } from './pages/dashboard/QuestionsTable';
import { FormEditById } from './pages/dashboard/FormEditById';
import { ManagementPanelBordereau } from './pages/dashboard/Bordereau';

// types and classes
import { RouteComponentProps } from '@reach/router';

// ----------------------------------------------------------------------
const ManagementPanel = (_props: RouteComponentProps) => {
  const iconLink = document.querySelector(
    "link[rel*='icon']",
  ) as HTMLLinkElement;
  if (iconLink) {
    iconLink.href = '/static/brand/favicon.ico';
  }
  return (
    // @ts-expect-error
    <Authenticated>
      <RtlLayout>
        <ThemePrimaryColor>
          <IsManagerProvider value={true}>
            <ThemeConfig>
              <CollapseDrawerProvider>
                <SettingsProvider>
                  <GlobalStyles />
                  <ProgressBarStyle />
                  <Settings />
                  <ScrollToTop />
                  <Router>
                    <DashboardLayout path='/'>
                      <Redirect
                        from='/'
                        to='/insurtech/manager/dashboard'
                        noThrow
                      />
                      <Dashboard path='dashboard' />

                      {/* Quotes */}
                      <QuoteList path='quotes' />
                      <QuoteCreate path='/quotes/create' />
                      <QuoteDetails path='/quotes/details/:uniqueID' />
                      <InsurerQuoteDetails path='/quotes/details/:uniqueID/:insurerQuoteID' />

                      {/* Forms */}
                      <FormList path='/forms' />
                      <FormCreate path='/forms/create' />
                      <FormEdit path='/forms/edit/:businessId/:policyId' />
                      <FormEditById path='/forms/editbyid/:formId' />

                      {/* Form Modifiers */}
                      <FormModifiersList path='/formmodifiers' />
                      <FormModifierCreate path='/formmodifiers/create' />
                      <Redirect
                        from='/formmodifiers/edit'
                        to='/insurtech/manager/formmodifiers/create'
                        noThrow
                      />
                      <FormModifierEdit path='/formmodifiers/edit/:businessId/:policyId' />

                      {/* Rates */}
                      <RatesEditor path='/rates' />

                      {/* Bordereau */}
                      <ManagementPanelBordereau path='/bordereau' />

                      {/* Business Table */}
                      <BusinessList path='/database/business' />
                      <BusinessCreate path='/database/business/create' />
                      <BusinessEdit path='/database/business/:businessId/edit' />

                      {/* Policy Table */}
                      <PolicyList path='/database/policy' />
                      <PolicyCreate path='/database/policy/create' />
                      <PolicyEdit path='/database/policy/:policyId/edit' />

                      {/* Questions Table */}
                      <QuestionsTablePage path='/database/questions' />

                      <NotFound path='*' />
                    </DashboardLayout>
                  </Router>
                </SettingsProvider>
              </CollapseDrawerProvider>
            </ThemeConfig>
          </IsManagerProvider>
        </ThemePrimaryColor>
      </RtlLayout>
    </Authenticated>
  );
};
export default ManagementPanel;
