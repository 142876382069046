import theme from './HomeAutoTravelTheme';
import logo from './assets/MainLogo.png';
import squareLogo from './assets/HomeAutoTravelSquareLogo.png';
import favicon from './assets/favicon.ico';
import { WebsiteSettings } from '../../hooks/useSettings/WebsiteSettings';
import { Icon } from '@iconify/react';
import userIcon from '@iconify-icons/fa-solid/user';
import buildingIcon from '@iconify-icons/fa-solid/building';
import carAlt from '@iconify-icons/fa-solid/car-alt';
import homeIcon from '@iconify-icons/fa-solid/home';
import planeDeparture from '@iconify-icons/fa-solid/plane-departure';
import houseUser from '@iconify-icons/fa-solid/house-user';

const tileIcons = (internalName: string) => {
  switch (internalName) {
    case 'personal':
      return <Icon icon={userIcon} width={'35px'} />;
    case 'commercial':
      return <Icon icon={buildingIcon} width={'35px'} />;
    case 'automobile':
      return <Icon icon={carAlt} width={'35px'} />;
    case 'home':
      return <Icon icon={homeIcon} width={'35px'} />;
    case 'condo':
      return <Icon icon={buildingIcon} width={'35px'} />;
    case 'travel':
      return <Icon icon={planeDeparture} width={'35px'} />;
    case 'tenant':
      return <Icon icon={houseUser} width={'35px'} />;

    default:
      return null;
  }
};

// Lastname insurance Config
const settings = new WebsiteSettings({
  slug: 'homeautotravel',
  host: 'secure.homeautotravel.ca',
  personalInsurance: true,
  companyInsurType: 'both',
  companyName: 'Malcolm Setter',
  hours: '',
  phone: '',
  email: '',
  chatLink: '',
  companyTheme: theme,
  logo: logo,
  tileIcons: tileIcons,
  title: 'Home, Auto, Travel & More',
  favicon,
  squareLogo,
  allowApplicationUpload: true,
});

export default settings;
