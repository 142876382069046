import { graphql } from '../../gql';

export const GET_TASK_POOL = graphql(`
  query getTaskPool($taskPoolId: ID!) {
    getTaskPool(taskPoolId: $taskPoolId) {
      id
      description
      additionalInformation
      error
      tasks {
        id
        description
        status
        order
        additionalInformation
        numSubtasks
        numCompletedSubtasks
      }
    }
  }
`);
